import { joinURL } from 'ufo'
import {
  defineNuxtRouteMiddleware,
  useRuntimeConfig,
  useAuth,
  navigateTo,
} from '#imports'

export default defineNuxtRouteMiddleware(async (to) => {
  const { APP_HOST } = useRuntimeConfig().public
  const { loggedIn, getBaseURL } = useAuth()

  if (!loggedIn.value) {
    const baseURL = await getBaseURL()

    return navigateTo(
      joinURL(
        baseURL,
        `/auth/?service=${joinURL(APP_HOST, encodeURI(to.fullPath))}`,
      ),
      {
        external: true,
      },
    )
  }
})
